import React from 'react';
import './css/patner.css';  // Ensure you have this CSS file

// Import logo images
// import logoKominfo from './images/hilook.png';
// import logoSSP from './images/hilook.png';
// import logoPbs from './images/hilook.png';
// import logoIcOnnet from './images/hilook.png';
// import logoBmkg from './images/hilook.png';
// import logoBri from './images/hilook.png';
// import logoPolywangi from './images/hilook.png';
// import logoKorban from './images/hilook.png';
// import logoRumahPrefab from './images/hilook.png';

import logoKominfo from '../pages/img/patner/bmkg.png';
import logoSSP from '../pages/img/patner/bri.jpg';
import logoPbs from '../pages/img/patner/kominfo.png';
import logoIcOnnet from '../pages/img/patner/ksp.jpg';
import logoBmkg from '../pages/img/patner/lss.jpg';
import logoBri from '../pages/img/patner/pss.png';
import logoPolywangi from '../pages/img/patner/q1.png';
import logoKorban from '../pages/img/patner/q2.png';
import logoRumahPrefab from '../pages/img/patner/qq.png';
// import ssp from '../pages/img/patner/ssp.png';
// import yy from '../pages/img/patner/yy.png';


function Partner() {
    const logos = [logoKominfo, logoSSP, logoPbs, logoRumahPrefab, logoIcOnnet, logoBri, logoBmkg, logoPolywangi, logoKorban, logoRumahPrefab]; // Example array of logos
    return (
        <div className="container text-center my-4">
        <div className="d-flex flex-wrap justify-content-center gap-5">
            {logos.map((logo, index) => (
            <div className="col-2 mb-3" key={index}>
                <img src={logo} alt={`Logo ${index}`} className="img-fluid partner-logo" />
            </div>
            ))}
        </div>
        </div>
    );
}

export default Partner;
