import React from 'react';

function ServiceCard({ icon, title, description }) {
  return (
    <>
        <div className="col-md-4 mb-4">
        <div className="card h-100 border-0 shadow">
            <div className="card-body text-center">
            <div className="mb-3 icon-container" style={{ fontSize: '2rem' }}>
                {icon}
            </div>
            <h5 className="card-title poppins-semibold">{title}</h5>
            <p className="card-text poppins-regular">{description}</p>
            </div>
        </div>
        </div>
    </>
  );
}

export default ServiceCard;
