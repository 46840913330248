import React from 'react';
import logou from './img/logo5.jpg';
import aboutIcon1 from './img/icon/about1.png';
import aboutIcon2 from './img/icon/about2.png';
import smileyWinkIcon from './img/icon/SmileyWink.png';
import aboutIcon4 from './img/icon/about4.png';
import whyCekIcon from './img/icon/whycek.png';
import whyPhoneIcon from './img/icon/whyphone.png';
import whyTouchpIcon from './img/icon/whytouchp.png';
import Partners from '../components/Patner';


const ReviewCard = ({ review }) => {
    return (
        <div className="card shadow mx-auto border-0 mb-5" style={{ maxWidth: '25rem', backgroundColor: '#F5F5F5' }}>
            <div className="card-body">
            <div className='d-flex justify-content-between'>
            <h5 className="card-title text-center" style={{ color: '#FFCC00', fontSize: '24px', marginBottom: '16px' }}>★★★★★</h5>
            <p style={{ fontSize: '14px', color: '#666' }}>{review.tanggal}</p>
            </div>
            <p className="card-text" style={{ fontSize: '14px', lineHeight: '20px', color: '#333' }}>{review.text}</p>
            </div>
            <div className="card-footer" style={{ backgroundColor: 'transparent', borderTop: 'none' }}>
            <div className="d-flex align-items-center justify-content-center">
                <img src={review.avatar} alt={review.name} style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
                <div className="ms-3">
                <h6 className="mb-0" style={{ fontSize: '16px', color: '#000' }}>{review.name}</h6>
                <p className="text-muted mb-0" style={{ fontSize: '14px', color: '#666' }}>{review.position}</p>
                </div>
            </div>
            </div>
        </div>
        );
    };
        

const About = () => {
    const review = {
        name: 'Abdul Mutholib',
        tanggal: '11 September 2024',
        position: 'Pelanggan',
        avatar: 'https://itb.ac.id/files/dokumentasi/1658740361-112624.jpg',
        text: 'Pemasangan rapih, garansi jelas, pelayanan sangat ramah dalam memberikan rekomendasi terkait kebutuhan saya. Sangat direkomendasikan untuk layanan profesional!'
    };
    
    const review2 = {
        name: 'faradatis',
        tanggal: '22 Oktober 2024',
        position: 'Pelanggan',
        avatar: 'https://awsimages.detik.net.id/community/media/visual/2019/04/02/0b967a4e-0e4c-49de-8aa3-bb340a747667.jpeg?w=650&q=90',
        text: 'Tim yang sangat terampil dan berpengetahuan. Mereka menyelesaikan proyek sebelum deadline dengan hasil yang melebihi ekspektasi kami. Sangat mengesankan!'
    };
    
    const review3 = {
        name: 'Lukito',
        tanggal: '30 November 2024',
        position: 'Pelanggan',
        avatar: 'https://media.licdn.com/dms/image/v2/D5603AQEAmhlv0fGsWA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1691988056487?e=2147483647&v=beta&t=a9pcnnRUm0NnKXNX5UNEJRHqjHn3c3hvY-tdY9yrFQI',
        text: 'Kualitas dan keandalan tanpa tanding! Layanan purna jual mereka juga sangat responsif dan membantu. Ini adalah jenis dukungan yang membuat saya tetap sebagai pelanggan setia.'
    };
    

    return (
        <div>
            <div className="container">
                <div className="row pt-5">
                    <div className="col-md-4 order-2 order-md-1 d-flex justify-content-center align-items-center">
                        <img className="img-fluid" src={logou} alt="Phone" />
                    </div>
                    <div className="col order-2 order-md-1">
                        <h2 className="poppins-semibold text-danger">CCTV BANYUWANGI</h2>
                        <p className="poppins-regular pt-3" style={{ fontSize: '17px', textAlign: 'justify' }}>
                            CCTV Banyuwangi adalah spesialis Anda dalam pemasangan dan konsultasi sistem CCTV di Banyuwangi. Kami berdedikasi untuk menyediakan layanan yang aman dan terpercaya, dengan penekanan pada solusi yang disesuaikan untuk memenuhi kebutuhan keamanan Anda. Dengan pengalaman bertahun-tahun, kami telah dipercaya oleh banyak pelanggan untuk meningkatkan sistem keamanan mereka melalui teknologi terkini dan dukungan teknis yang handal.
                        </p>
                        <div className="row stat-row">
                            <div className="col-md-3 text-center stat-item">
                                <img src={aboutIcon1} className="img-fluid mb-2" style={{ width: '35px' }} alt="Icon" />
                                <h5 className="mt-2 poppins-semibold">14 Tahun</h5>
                                <p className="poppins-regular">Berdiri Sejak 2010</p>
                            </div>
                            <div className="col-md-3 text-center stat-item">
                                <img src={aboutIcon2} className="img-fluid mb-2" style={{ width: '35px' }} alt="Icon" />
                                <h5 className="mt-2 poppins-semibold">100+</h5>
                                <p className="poppins-regular">Produk Tersedia</p>
                            </div>
                            <div className="col-md-3 text-center stat-item">
                                <img src={smileyWinkIcon} className="img-fluid mb-2" style={{ width: '35px' }} alt="Icon" />
                                <h5 className="mt-2 poppins-semibold">10rb+</h5>
                                <p className="poppins-regular">Pelanggan Puas</p>
                            </div>
                            <div className="col-md-3 text-center stat-item">
                                <img src={aboutIcon4} className="img-fluid mb-2" style={{ width: '35px' }} alt="Icon" />
                                <h5 className="mt-2 poppins-semibold">#1</h5>
                                <p className="poppins-regular">Terbaik Di Banyuwangi</p>
                            </div>
                        </div>            
                    </div>
                </div>
            </div>

            <div className="container mt-5 pt-5 pb-5 mb-4">
                <div className="row">
                <div className="col-md-6">
                    <h4 className="text-danger poppins-semibold">
                    <span className="brand-line"></span>Kenapa Harus Di CCTV Banyuwangi ?
                    </h4>
                    <p className="poppins-regular">
                    <p className="poppins-regular" style={{ textAlign: 'justify' }}>
                    Kami menawarkan solusi keamanan terpadu yang disesuaikan secara khusus untuk memenuhi kebutuhan Anda, dilengkapi dengan teknologi terkini.
                    </p>
                    </p>
                    <div className="list-unstyled">
                    <div className="border border-1 rounded-4 p-2 mb-3 shadow d-flex align-items-center justify-content-start p-2">
                        <img className="p-2" width="70px" src={whyCekIcon} alt="Icon" />
                        <div>
                        <p className="mb-0 poppins-semibold" style={{ fontSize: '18px' }}>Pemasangan Profesional</p>
                        <p className="mb-0 poppins-regular" style={{ fontSize: '14px' }}>Teknisi kami yang bersertifikat memastikan pemasangan yang rapi dan efisien, dengan sedikit gangguan pada aktivitas sehari-hari Anda.</p>
                        </div>
                    </div>
                    <div className="border border-1 rounded-4 p-2 mb-3 shadow d-flex align-items-center justify-content-start p-2">
                        <img className="p-2" width="70px" src={whyPhoneIcon} alt="Icon" />
                        <div>
                        <p className="mb-0 poppins-semibold" style={{ fontSize: '18px' }}>Dukungan Teknis 24/7</p>
                        <p className="mb-0 poppins-regular" style={{ fontSize: '14px' }}>Dukungan teknis kami tersedia kapan saja untuk membantu Anda dengan setiap masalah atau pertanyaan yang mungkin Anda miliki.</p>
                        </div>
                    </div>
                    <div className="border border-1 rounded-4 p-2 mb-3 shadow d-flex align-items-center justify-content-start p-2">
                        <img className="p-2" width="70px" src={whyTouchpIcon} alt="Icon" />
                        <div>
                        <p className="mb-0 poppins-semibold" style={{ fontSize: '18px' }}>Solusi Keamanan Terintegrasi</p>
                        <p className="mb-0 poppins-regular" style={{ fontSize: '14px' }}>Kami menawarkan solusi keamanan yang terintegrasi termasuk CCTV, alarm, dan sistem kontrol akses untuk perlindungan maksimal.</p>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="col-md-6 mt-5">
                    <div style={{ position: 'relative', paddingBottom: '56.25%', height: '0', overflow: 'hidden' }}>
                    <iframe 
                        src="https://www.youtube.com/embed/Tv1Q2xNB6Ic?si=6XQsTWnul8yK-mf0"
                        style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }} 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                        allowFullScreen 
                        title="YouTube video player"
                    />
                    </div>
                </div>
                </div>
            </div>
            
            <h3 className='text-center mb-4 poppins-bold'>ULASAN PELANGGAN</h3>
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex justify-content-around">
                    <ReviewCard review={review} />
                    <ReviewCard review={review2} />
                    <ReviewCard review={review3} />
                    </div>
                </div>
            </div>

            <div>
                <div className='text-center mb-5'>
                <h2 className='poppins-bold'>
                    Patner Kami
                </h2>
                <p className='poppins-regular slug-t' style={{marginTop:'-10px'}}>Perusahaan dan instansi yang Mempercayakan kami</p>
                    <Partners />
                </div>
            </div>

            <div className='container mt-5 mb-5' style={{ width: '100%' }}>
                <h2 className='text-center poppins-bold mb-4'>
                    Alamat Kami
                </h2>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7897.7113050311145!2d114.35868844350232!3d-8.217268092475626!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd1452dbdae16a1%3A0x1cb7e8422920efad!2sCctv%20Banyuwangi!5e0!3m2!1sen!2sid!4v1725946930633!5m2!1sen!2sid"
                    style={{ width: '100%', height: '450px', border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Lokasi Kantor Cctv Banyuwangi"
                />
            </div>
        </div>
);
};

export default About;
