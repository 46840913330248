import React from 'react';
import g1 from './img/portofolio/1.jpg';
import g2 from './img/portofolio/2.jpg';
import g3 from './img/portofolio/3.jpg';
import g4 from './img/portofolio/4.jpg';
import g5 from './img/portofolio/5.jpg';
import g6 from './img/portofolio/6.jpg';
import g7 from './img/portofolio/7.jpg';
import g8 from './img/portofolio/8.jpg';
import g9 from './img/portofolio/9.jpg';

const Portofolio = () => {
    return (
        <div className='container mt-5'>
            <div className="row">
                <div className="col-lg-4 col-md-12 mb-4 mb-lg-0">
                    <img
                    src={g1}
                    className="w-100 shadow-1-strong rounded mb-4"
                    alt="Boat on Calm Water"
                    />

                    <img
                    src={g8}
                    className="w-100 shadow-1-strong rounded mb-4"
                    alt="Wintry Mountain Landscape"
                    />
                </div>

                <div className="col-lg-4 mb-4 mb-lg-0">
                    <img
                    src={g3}
                    className="w-100 shadow-1-strong rounded mb-4"
                    alt="Mountains in the Clouds"
                    />

                    <img
                    src={g4}
                    className="w-100 shadow-1-strong rounded mb-4"
                    alt="Boat on Calm Water"
                    />
                    <img
                    src={g2}
                    className="w-100 shadow-1-strong rounded mb-4"
                    alt="Boat on Calm Water"
                    />
                    <img
                    src={g9}
                    className="w-100 shadow-1-strong rounded mb-4"
                    alt="Boat on Calm Water"
                    />
                </div>

                <div className="col-lg-4 mb-4 mb-lg-0">
                    <img
                    src={g5}
                    className="w-100 shadow-1-strong rounded mb-4"
                    alt="Waves at Sea"
                    />

                    <img
                    src={g6}
                    className="w-100 shadow-1-strong rounded mb-4"
                    alt="Yosemite National Park"
                    />
                </div>
            </div>
        </div>
    );
};

export default Portofolio;
