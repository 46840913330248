import React from 'react';
import './css/product.css'; // Import your custom CSS here
import ProductCard from '../components/ProductCard';

import paket1 from '../pages/img/imgcctv/Paket1.jpg';

import ezviz1 from '../pages/img/imgcctv/ezviz11.png';
import ezviz2 from '../pages/img/imgcctv/ezviz2.webp';
import ezviz3 from '../pages/img/imgcctv/ezviz3.webp';
import ezviz4 from '../pages/img/imgcctv/ezviz4.jpg';

import hik1 from '../pages/img/imgcctv/hik1.jpg';
import hik2 from '../pages/img/imgcctv/hik2.jpg';
import hik3 from '../pages/img/imgcctv/hik3.jpg';
import hik4 from '../pages/img/imgcctv/hik4.jpg';

function Product() {
  return (
    <div>
      {/* Alert and Loading modal components */}
      <div id="alert-form" style={{ paddingTop: '0px', paddingLeft: '20px' }}></div>

      <div id="loading-modal" style={{ display: 'none' }}>
        <div className="loading-modal-content">
          <p id="loading-text">Loading...</p>
          <div className="loading-animation">
            {/* Animation can be added here */}
          </div>
        </div>
      </div>

      {/* Content header with background image */}
      <div className="image-background p-3 mb-5">
      <h3 className="text-white poppins-extrabold title mt-3">Produk</h3>
      {/* <p className="text-white poppins-regular t-desc spacing-1 mt-4">
  Jelajahi produk yang sesuai dengan keinginan anda 
</p> */}




        
        <nav aria-label="breadcrumb mt-2">
          <ol className="breadcrumb">
            {/* <li className="breadcrumb-item poppins-light"><a href="#">Beranda</a></li>
            <li className="breadcrumb-item poppins-medium active text-white" aria-current="page">Produk</li> */}
          </ol>
        </nav>
      </div>

      {/* Content body */}
      <div className="container mt-3">
        <div className="row">
          <div className="col-md-12">
            {/* Product listing and sort options */}
            <div className="d-flex justify-content-between align-items-center w-100">
              {/* Search and sort UI */}
            </div>
            <p className='poppins-extrabold ' style={{fontSize:"30px"}}>Promo Paket Bundle </p>
            <span style={{
                            display: 'block',
                            width: '70px',
                            height: '4px',
                            backgroundColor: 'red',
                            marginTop: '-15px',
                            marginBottom: '10px'
                        }}>
                        </span>
            <div className='row mt-4'>
            <ProductCard
                imageUrl={paket1} 
                title="Paket Hemat Rumah" 
                description="Paket termasuk 2 kamera dome 2MP dan 1 kamera bullet 4MP dengan perekaman 24/7 dan akses remote via smartphone." // Updated description
                price="Rp 2.500.000" 
                discount="15% Off" 
            />

            <ProductCard
                imageUrl={paket1} 
                title="Paket Usaha Kecil" 
                description="Kompleks dengan 4 kamera dome 4MP, DVR, dan penyimpanan cloud. Ideal untuk toko dan kafe kecil." // Updated description
                price="Rp 4.800.000" 
                discount="10% Off" 
            />

            <ProductCard
                imageUrl={paket1} 
                title="Paket Premium" 
                description="6 kamera HD dengan analitik canggih, termasuk pengenalan wajah dan pelacakan gerakan. Dukungan teknis 24/7 dan instalasi profesional." // Updated description
                price="Rp 10.000.000" 
                discount="20% Off" 
            />

            <ProductCard
                imageUrl={paket1} 
                title="Paket Premium 1" 
                description="6 kamera HD dengan analitik canggih, termasuk pengenalan wajah dan pelacakan gerakan. Dukungan teknis 24/7 dan instalasi profesional." // Updated description
                price="Rp 10.000.000" 
                discount="20% Off"
            />
            </div>

            <p className='poppins-extrabold' style={{fontSize:"30px"}}> Produk</p>
            <span style={{
                            display: 'block',
                            width: '70px',
                            height: '4px',
                            backgroundColor: 'red',
                            marginTop: '-15px',
                            marginBottom: '10px'
                        }}>
                        </span>
            <div className="row pt-3" id="productListContainer">
            <ProductCard
    imageUrl={ezviz1} 
    title="Ezviz C6N Smart Wi-Fi Pan & Tilt Camera" // Product title
    description="Full HD 1080p resolution with two-way talk and smart tracking. Features motorized pan and tilt for 360-degree visual coverage. Smart IR up to 10m (33 ft)." // Updated description
    price="Rp 850.000" 
    discount="15% Off" 
/>

<ProductCard
    imageUrl={ezviz2} 
    title="Ezviz H8C 4MP Smart Wi-Fi Pan & Tilt Camera" // Product title
    description="2K resolution with H.265 compression. Includes two-way talk, auto tracking, and active defense with siren and strobe light. Color night vision supported." // Updated description
    price="Rp 1.200.000" 
    discount="" 
/>

<ProductCard
    imageUrl={ezviz3} 
    title="Ezviz TY1 2MP Smart Wi-Fi Pan & Tilt Camera" // Product title
    description="Full HD 1080p resolution with H.264 video compression. Features motion detection, night vision up to 10m (33 ft), and two-way talk." // Updated description
    price="Rp 2.999.000" 
    discount="20% Off" 
/>

<ProductCard
    imageUrl={ezviz4} 
    title="Ezviz BC2 Wi-Fi Smart Home Battery Camera" // Product title
    description="Full HD 1080p resolution camera with two-way talk and human detection. Long battery life up to 2000 mAh, supporting up to 50 days. Voice control via Google Assistant and Alexa." // Updated description
    price="Rp 1.750.000" 
    discount="5% Off" 
/>


<ProductCard
    imageUrl={hik1} 
    title="Hikvision 2MP Bullet Camera" // Product title
    description="High quality imaging with 2 MP resolution. Supports human and vehicle detection, smart dual-light built-in microphone. Weather and dust resistant (IP67), supports on-board storage up to 256 GB." // Updated description
    price="Rp 1.500.000" 
    discount="12% Off" 
/>

<ProductCard
    imageUrl={hik2} 
    title="Hikvision 2MP Fixed Turret IP Camera" // Product title
    description="2MP resolution 1920x1080 camera. Built-in Audio Microphone, supports power over ethernet POE 48V, equipped with human motion detection." // Updated description
    price="Rp 3.300.000" 
    discount="10% Off" 
/>

<ProductCard
    imageUrl={hik3} 
    title="Hikvision 4MP IR Mini PT IP Camera" // Product title
    description="4MP resolution 2560x1440 camera. Camera can pan and tilt, built-in mic for sound capture & speaker can emit sound, built-in Micro SD slot up to 512GB." // Updated description
    price="Rp 3.300.000" 
    discount="10% Off" 
/>

<ProductCard
    imageUrl={hik4} 
    title="Hikvision 2MP Fixed Turret IP Camera" // Product title
    description="2MP resolution 1920x1080 camera. Built-in Audio Microphone, supports power over ethernet POE 48V, equipped with human motion detection." // Updated description
    price="Rp 3.300.000" 
    discount="10% Off" 
/>
            </div>
            <div className="d-flex justify-content-center">
              <div>
<ul className="pagination" id="paginationContainer">
    <li className="page-item text-bg-danger">
        <a 
            className="page-link btn-danger text-danger" 
            href="https://wa.me/6281234567890?text=Saya%20tertarik%20dengan%20produk%20Anda,%20apakah%20bisa%20bantu%20saya%20?" 
            target="_blank" 
            rel="noopener noreferrer"
            aria-label="Next"
        >
            Selanjutnya <span aria-hidden="true">&raquo;</span>
        </a>
    </li>
</ul>



              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Product;