import React from 'react';
import { motion } from 'framer-motion';
import { FaWhatsapp } from 'react-icons/fa'; // Import WhatsApp icon

function WhatsAppButton() {
  return (
    <motion.a href="https://wa.me/6281217555948?text=Saya%20tertarik%20dengan%20produk%20dari%20CCTV%20Banyuwangi,%20apakah%20bisa%20bantu%20saya%20?"
      style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        backgroundColor: '#25D366',
        color: 'white',
        borderRadius: '50%',
        padding: '10px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '24px',
        boxShadow: '0 2px 6px rgba(0,0,0,0.3)'
      }}
      whileHover={{ scale: 1.1 }} 
      whileTap={{ scale: 0.9 }}  // Scale down to 90% when tapped
      initial={{ scale: 1 }} // Initial scale is 100%
      transition={{ type: 'spring', stiffness: 300 }} // Spring animation for smooth effect
    >
      <FaWhatsapp />
    </motion.a>
  );
}

export default WhatsAppButton;
