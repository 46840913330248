import React from 'react';
import './css/footer.css';
import { NavLink } from 'react-router-dom';

const Footer = () => {
    return (
        <footer className="footer-background text-center text-lg-start text-white">
            <div className="container p-4 pb-5 footer-content">
                <section>
                    <div className="row">
                        <div className="col-md-3 col-lg-3 col-xl-3 mx-auto mt-3 footer-menu">
                            <h6 className="text-uppercase mb-4 poppins-medium">
                                CCTV Banyuwangi
                            </h6>
                            <p className='poppins-regular sizefont'>
                                Penyedia layanan CCTV terpercaya di Banyuwangi dengan produk berkualitas dan instalasi profesional berpengalaman.
                            </p>
                        </div>

                        <hr className="w-100 clearfix d-md-none footer-menu " />

                        <div className="col-md-4 col-lg-4 col-xl-4 mx-auto mt-3 footer-menu">
                            <h6 className="text-uppercase text-center mb-4  poppins-medium">Menu</h6>
                            <div className="d-flex justify-content-center gap-5">
                                <div>
                                    <p className='poppins-regular'>
                                        <NavLink to="/" className="text-white" style={{ textDecoration: 'none' }}>Beranda</NavLink>
                                    </p>
                                    <p className='poppins-regular'>
                                        <NavLink to="/product" className="text-white" style={{ textDecoration: 'none' }}>Product</NavLink>
                                    </p>
                                    <p className='poppins-regular'>
                                        <NavLink to="/services" className="text-white" style={{ textDecoration: 'none' }}>Layanan</NavLink>
                                    </p>
                                    <p className='poppins-regular'>
                                        <NavLink to="/blog" className="text-white" style={{ textDecoration: 'none' }}>Blog</NavLink>
                                    </p>
                                </div>
                                <div>
                                    <p className='poppins-regular'>
                                        <NavLink to="/about" className="text-white" style={{ textDecoration: 'none' }}>Tentang Kami</NavLink>
                                    </p>
                                    <p className='poppins-regular'>
                                        <NavLink to="/office-location" className="text-white" style={{ textDecoration: 'none' }}>Alamat Kantor</NavLink>
                                    </p>
                                    <p className='poppins-regular'>
                                        <NavLink to="/portfolio" className="text-white" style={{ textDecoration: 'none' }}>Portofolio</NavLink>
                                    </p>
                                    <p className='poppins-regular'>
                                        <NavLink to="/support" className="text-white" style={{ textDecoration: 'none' }}>Support</NavLink>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <hr className="w-100 clearfix d-md-none footer-menu" />

                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto ">
                            <h6 className="text-uppercase mb-4 font-weight-bold">Kontak</h6>
                            <p className='poppins-regular sizefont'>Ruko Mendut De West Cluster, Taman Baru, Mojopanggung, Kec. Banyuwangi, Kabupaten Banyuwangi, Jawa Timur 68416</p>
                            <p className='poppins-regular sizefont'><i className="fas fa-envelope mr-3"></i> admin@cctvbanyuwangi.id</p>
                            <p className='poppins-regular sizefont'><i className="fas fa-phone mr-3"></i> 081217555948</p>
                        </div>

                        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mt-3">
                            <h6 className="text-uppercase mb-4 font-weight-bold">Sosial Media :</h6>

                            <a
                                className="btn btn-primary btn-floating m-1"
                                style={{ backgroundColor: '#3b5998' }}
                                href="#!"
                                role="button"
                            ><i className="fab fa-facebook-f"></i></a>

                            <a
                                className="btn btn-primary btn-floating m-1"
                                style={{ backgroundColor: '#55acee' }}
                                href="#!"
                                role="button"
                            ><i className="fab fa-twitter"></i></a>

                            <a
                                className="btn btn-primary btn-floating m-1"
                                style={{ backgroundColor: '#dd4b39' }}
                                href="#!"
                                role="button"
                            ><i className="fab fa-google"></i></a>

                            <a
                                className="btn btn-primary btn-floating m-1"
                                style={{ backgroundColor: '#ac2bac' }}
                                href="#!"
                                role="button"
                            ><i className="fab fa-instagram"></i></a>

                            <a
                                className="btn btn-primary btn-floating m-1"
                                style={{ backgroundColor: '#0082ca' }}
                                href="#!"
                                role="button"
                            ><i className="fab fa-linkedin-in"></i></a>

                            <a
                                className="btn btn-primary btn-floating m-1"
                                style={{ backgroundColor: '#333333' }}
                                href="#!"
                                role="button"
                            ><i className="fab fa-github"></i></a>
                        </div>
                    </div>
                </section>
            </div>

            <div
                className="text-center p-3 footer-content"
                style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
            >
                <a className="text-white poppins-regular sizefont" href="https://mdbootstrap.com/" style={{ textDecoration: 'none' }}>© 2024 Copyright CCTV Banyuwangi by Manggala Adi Solusindo</a>
            </div>
        </footer>
    );
};

export default Footer;
