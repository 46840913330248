import React from 'react';
import ServiceCard from '../components/ServiceCard';
import { FaVideo, FaToolbox, FaShieldAlt, FaUserShield, FaPhoneVolume, FaHandsHelping, FaChalkboardTeacher, FaMicroscope, FaCertificate, } from 'react-icons/fa';

function Services() {
    const services = [
        { icon: <FaVideo />, title: "Pengalaman Terpercaya", description: "Menerapkan solusi CCTV yang disesuaikan dengan kebutuhan proses bisnis Anda." },
        { icon: <FaToolbox />, title: "Instalasi Profesional", description: "Pemasangan yang rapi dan terintegrasi, memperkuat identitas dan keamanan usaha Anda." },
        { icon: <FaShieldAlt />, title: "Garansi Resmi", description: "Menjamin setiap instalasi dengan garansi resmi, memberi Anda keamanan lebih dalam investasi." },
        { icon: <FaUserShield />, title: "Keamanan Maksimal", description: "Tingkatkan keamanan dengan sistem CCTV canggih yang mudah diakses dan diawasi." },
        { icon: <FaPhoneVolume />, title: "Dukungan Teknis 24/7", description: "Layanan dukungan teknis sepanjang waktu untuk menjaga sistem Anda beroperasi optimal." },
        { icon: <FaHandsHelping />, title: "Konsultasi Gratis", description: "Tawarkan konsultasi gratis untuk membantu Anda menentukan sistem keamanan terbaik sesuai kebutuhan." }
      ];
      

  return (
    <>
    <div className="container my-5">
      <h2 className="text-center mb-4 poppins-bold">Layanan Kami</h2>
      <div className="row">
        {services.map(service => (
          <ServiceCard key={service.title} icon={service.icon} title={service.title} description={service.description} />
        ))}
      </div>
    </div>
    <div style={{ backgroundColor: 'rgba(200, 200, 200, 0.2)', padding: '30px' }} className='rounded mt-5 mb-5'>
                <div className="container">
                    <h2 className="text-center poppins-bold mb-4 pb-2 mt-3 haruskami-t ">KENAPA HARUS DI CCTV BANYUWANGI?</h2>
                    <div className="d-flex justify-content-center">
                        <span style={{
                            display: 'block',
                            width: '70px',
                            height: '3px',
                            backgroundColor: 'red',
                            marginTop: '-15px'
                        }}>
                        </span>
                    </div>
                    <div className="row text-center justify-content-center g-4 custom-gap p-3">
                        <div className="col-md-3 rounded-4 shadow p-3 effectCard" >
                            <FaChalkboardTeacher size={50} className="text-danger mb-3" />
                            <h4 className="font-weight-bold poppins-bold haruskami1">Garansi Resmi</h4>
                            <p className="poppins-regular haruskami">
                                Setiap pembelian dilengkapi garansi resmi, memastikan kualitas dan keamanan jangka panjang.
                            </p>
                        </div>

                        <div className="col-md-3 rounded-4 shadow p-3 effectCard" >
                            <FaMicroscope size={50} className="text-danger mb-3" />
                            <h4 className="font-weight-bold poppins-bold haruskami1">Pemasangan Rapi</h4>
                            <p className="poppins-regular haruskami">
                                Pemasangan cepat dan rapi, memberikan hasil yang bersih dan siap pakai.
                            </p>
                        </div>

                        <div className="col-md-3 rounded-4 shadow p-3 effectCard" >
                            <FaCertificate size={50} className="text-danger mb-3" />
                            <h4 className="font-weight-bold poppins-bold haruskami1">20 Tahun Pengalaman</h4>
                            <p className="poppins-regular haruskami">
                                Berpengalaman lebih dari 20 tahun, memberikan solusi keamanan terbaik.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container mt-5 mb-5' style={{ width: '100%' }}>
                <h2 className='text-center poppins-bold mb-4'>
                    Alamat Kami
                </h2>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7897.7113050311145!2d114.35868844350232!3d-8.217268092475626!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2dd1452dbdae16a1%3A0x1cb7e8422920efad!2sCctv%20Banyuwangi!5e0!3m2!1sen!2sid!4v1725946930633!5m2!1sen!2sid"
                    style={{ width: '100%', height: '450px', border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    title="Lokasi Kantor Cctv Banyuwangi"
                />
            </div>
    
    </>
  );
}

export default Services;
